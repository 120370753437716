var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"itemRecipe"},[(!_vm.firstRecipe)?_c('div',{staticClass:"horizontalLine"}):_vm._e(),(_vm.activeRecipes.length > 1)?_c('div',{staticClass:"verticalLine verticalLine--variants"}):_vm._e(),_c('h3',[_c('v-container',{staticClass:"pl-2"},[_c('v-row',{staticClass:"mx-0"},[_vm._v(" "+_vm._s(_vm.amount)+" x "+_vm._s(_vm.selectedRecipeNameLocalized)+" "),(_vm.activeRecipes.length === 0)?_c('span',{staticClass:"label-info py-1"},[_vm._v(_vm._s(_vm.craftedOrFound({})))]):(_vm.activeRecipes.length === 1)?_c('span',{staticClass:"label-info py-1"},[_vm._v(_vm._s(_vm.craftedOrFound(_vm.activeRecipes[0])))]):_vm._e(),(_vm.activeRecipes.length === 1)?_c('v-btn',{staticClass:"ml-2 mt-1",attrs:{"color":"primary","x-small":"","hide-details":""},on:{"click":function($event){return _vm.addTodoListItem({ name: _vm.selectedRecipe, index: 0 })}}},[_vm._v(" + Todo list ")]):_vm._e()],1)],1)],1),(_vm.activeRecipes.length > 0)?_c('div',{staticClass:"itemRecipeContent",class:{ 'itemRecipeContent--variants': _vm.activeRecipes.length > 1 }},_vm._l((_vm.activeRecipes),function(r,variantIndex){return _c('div',{key:variantIndex,class:{ recipeVariant: _vm.activeRecipes.length > 1 }},[(r.ingredient && _vm.activeRecipes.length > 1)?_c('div',{staticClass:"horizontalLine"}):_vm._e(),(_vm.activeRecipes.length > 1 && r.ingredient)?_c('v-container',[_c('v-row',[_c('v-radio-group',{staticClass:"mt-0",attrs:{"hide-details":""},on:{"change":_vm.recipeChangeHandler},model:{value:(_vm.activeRecipes[0].selectedVariant),callback:function ($$v) {_vm.$set(_vm.activeRecipes[0], "selectedVariant", $$v)},expression:"activeRecipes[0].selectedVariant"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-radio',_vm._g(_vm._b({attrs:{"value":variantIndex}},'v-radio',attrs,false),on))]}}],null,true)},[_c('span',[_vm._v("Use this ingredient variant for todo lists")])])],1),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-1",attrs:{"icon":""},on:{"click":function () { return (r.show = !r.show); }}},'v-btn',attrs,false),on),[(r.show)?_c('v-icon',[_vm._v("mdi-eye")]):_c('v-icon',[_vm._v("mdi-eye-off")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(r.show ? 'hide variant' : 'show variant'))])]),_c('div',{staticClass:"d-flex align-center"},[_vm._v(" variant "+_vm._s(variantIndex + 1)+" "),_c('span',{staticClass:"label-info"},[_vm._v(_vm._s(_vm.craftedOrFound(r)))])]),_c('v-fade-transition',[(r.show)?_c('v-btn',{staticClass:"ml-2 mt-2",attrs:{"color":"primary","x-small":"","hide-details":""},on:{"click":function($event){return _vm.addTodoListItem({ name: _vm.selectedRecipe, index: variantIndex })}}},[_vm._v(" + Todo list ")]):_vm._e()],1)],1)],1):_vm._e(),_c('v-expand-transition',[(r.ingredient)?_c('div',{directives:[{name:"show",rawName:"v-show",value:(
            r.ingredient &&
            (_vm.activeRecipes.length === 1 ||
              (_vm.activeRecipes.length > 1 && r.show))
          ),expression:"\n            r.ingredient &&\n            (activeRecipes.length === 1 ||\n              (activeRecipes.length > 1 && r.show))\n          "}],staticClass:"recipeIngredient"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(
              r.ingredient &&
              (_vm.activeRecipes.length === 1 ||
                (_vm.activeRecipes.length > 1 && r.show))
            ),expression:"\n              r.ingredient &&\n              (activeRecipes.length === 1 ||\n                (activeRecipes.length > 1 && r.show))\n            "}],staticClass:"verticalLine"}),_vm._l((r.ingredient),function(ingr,ingrIndex){return _c('itemRecipe',{key:ingr.name[0] + ingrIndex,attrs:{"selectedRecipe":ingr.name[0],"amount":parseInt(ingr.count[0]) * _vm.amount,"treeCallback":_vm.recipeTreeCallback,"parentVariantIndex":variantIndex}})})],2):_vm._e()])],1)}),0):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }