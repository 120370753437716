<template>
  <div class="home">
    <RecipeSearch v-if="selectedGameVersion !== ''" />
  </div>
</template>

<script>
// @ is an alias to /src
import RecipeSearch from '@/components/RecipeSearch';
import { mapState } from 'vuex';

export default {
  name: 'Home',
  components: {
    RecipeSearch,
  },
  computed: {
    ...mapState({
      selectedGameVersion: 'settings/selectedGameVersion',
    }),
  },
};
</script>
