var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ma-2 todoItem"},[_c('v-row',{staticStyle:{"position":"relative"},attrs:{"dense":""},on:{"contextmenu":function($event){$event.preventDefault();return _vm.showMenu()}}},[_c('v-expand-x-transition',[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.item.completed),expression:"item.completed"}],staticClass:"completedIndicator completedIndicator--item"})]),_c('v-col',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticClass:"text-button item-link",on:{"click":function () {
                _vm.$store.dispatch('updateSearch', {
                  term: _vm.item.name,
                  amount: _vm.item.amount,
                });

                _vm.itemClickCallback();
              }}},'span',attrs,false),on),[_vm._v(" "+_vm._s(_vm.item.amount)+" "+_vm._s(_vm.item.nameLocalized)+" ")])]}}])},[_c('span',[_vm._v("View full recipe")])])],1),_c('v-col',{staticClass:"align-center d-flex",attrs:{"cols":"auto"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c('v-checkbox',{staticClass:"pa-0 my-0 mt-1 d-inline-flex",attrs:{"input-value":_vm.item.completed,"hide-details":""},on:{"change":function($event){return _vm.updateIngredient({ completed: !_vm.item.completed })}}})],1)]}}])},[_c('span',[_vm._v("Mark as completed")])]),_c('div',{staticClass:"d-inline-flex"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","x-small":""},on:{"click":function($event){return _vm.updateIngredient({ collapseList: !_vm.item.collapseList })}}},'v-btn',attrs,false),on),[(_vm.item.collapseList)?_c('v-icon',[_vm._v("mdi-arrow-expand-vertical")]):_vm._e(),(!_vm.item.collapseList)?_c('v-icon',[_vm._v("mdi-arrow-collapse-vertical")]):_vm._e()],1)]}}])},[(_vm.item.collapseList)?_c('span',[_vm._v("expand the list")]):_vm._e(),(!_vm.item.collapseList)?_c('span',[_vm._v("collapse the list")]):_vm._e()]),_c('v-menu',{attrs:{"bottom":"","left":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","x-small":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}]),model:{value:(_vm.menuActive),callback:function ($$v) {_vm.menuActive=$$v},expression:"menuActive"}},[_c('v-list',[_c('v-list-item',[_c('v-list-item-title',{staticClass:"menu-item",on:{"click":_vm.removeItem}},[_vm._v(" Remove ")])],1)],1)],1)],1)],1)],1),_c('v-expand-transition',[_c('TodoItemIngredient',{directives:[{name:"show",rawName:"v-show",value:(!_vm.item.collapseList),expression:"!item.collapseList"}],attrs:{"firstList":true,"objectPath":[_vm.itemIndex],"parentCompleted":_vm.item.completed}})],1),_c('v-divider')],1)}
var staticRenderFns = []

export { render, staticRenderFns }