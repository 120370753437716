import Vue from 'vue';
import Vuex from 'vuex';
import router from '@/router';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    siteVersion: '0.6', // for debugging use: Date.now().toString(),
    settings: {
      selectedGameVersion: '',
      selectedLocalization: 'english',
    },
    recipes: {},
    loadingRecipes: false,
    recipeSearchTerm: '',
    recipeSearchAmount: 1,
    localization: {
      // TODO: how to select (and make) languages and versions?
      // TODO: should localizations be versionized like the recipes?
      // If so, these wouldn't need to be in the state,
      // but just built into recipes and afterwards discarded
      keys: [],
      values: [],
    },
    todoList: localStorage.getItem('todoList')
      ? JSON.parse(localStorage.getItem('todoList'))
      : [],
  },
  mutations: {
    setGameVersion(state, payload) {
      // update the game version
      state.settings.selectedGameVersion = payload;
      localStorage.selectedGameVersion = payload;
    },
    updateLocalization(state, payload) {
      // update the localization keys and values
      state.localization.keys = payload.keys;
      state.localization.values = payload.values;
    },
    updateRecipes(state, payload) {
      // add localized names for the recipes
      // and set a show value
      for (let i = 0; i < payload.recipes.length; i++) {
        payload.recipes[i].show = true;
        payload.recipes[i].selectedVariant = 0;
        const indexOfLocalization = state.localization.keys.indexOf(
          payload.recipes[i].name[0]
        );

        // if the localization exist, use that, otherwise use the existing 'name' value
        if (indexOfLocalization !== -1) {
          payload.recipes[i].nameLocalized =
            state.localization.values[indexOfLocalization];
        } else {
          payload.recipes[i].nameLocalized = payload.recipes[i].name[0];
        }
      }

      // sort the recipes, first based on having ingredients or not, secondly alphabetical
      payload.recipes.sort((a, b) => {
        return (
          (a.ingredient === undefined) - (b.ingredient === undefined) ||
          a.nameLocalized.localeCompare(b.nameLocalized)
        );
      });

      Vue.set(state.recipes, payload.version, payload.recipes);
    },
    updateSearchTerm(state, payload) {
      // update the search term
      state.recipeSearchTerm = payload;

      // don't continue to push a new route, if we're already on it.
      // This usually happens if the user updates the history(back / forward buttons in browser or the like)
      if (
        router.currentRoute.params.recipe &&
        router.currentRoute.params.recipe === payload
      ) {
        return false;
      }

      // update the url, when a search term has been set
      // either point to a recipe or to "home"
      if (payload && payload.length > 0) {
        router.push({
          name: 'Recipe',
          params: { recipe: payload },
        });
      } else if (router.currentRoute.path !== '/') {
        router.push({
          name: 'Home',
        });
      }
    },
    setSearchAmount(state, payload) {
      state.recipeSearchAmount = payload;
    },
    pushToTodolist(state, payload) {
      state.todoList.push(payload);

      // save the todoList in localStorage
      localStorage.setItem('todoList', JSON.stringify(state.todoList));
    },
    updateTodoListIngredient(state, payload) {
      let foundItem;

      // go through all indexes in the path
      for (let i = 0; i < payload.path.length; i++) {
        const item = payload.path[i];

        // build the nested reference to the item
        if (i === 0) {
          foundItem = state.todoList[item];
        } else {
          foundItem = foundItem.children[item];
        }
      }

      // update the item with the payload changes
      foundItem = Object.assign(foundItem, payload.changes);
    },
    removeTodoListItem(state, payload) {
      state.todoList.splice(payload, 1);
    },
  },
  actions: {
    updateSearch({ commit }, { term, amount }) {
      commit('updateSearchTerm', term);
      commit('setSearchAmount', amount);
    },
    updateGameVersion({ state, commit, dispatch }, version) {
      // set the game version and call the fetchRecipes action
      commit('setGameVersion', version);

      // if the recipes already exists, don't update the recipes
      if (state.recipes[version] && state.recipes[version].length > 0) {
        return false;
      }

      // there are no recipes in localStorage, lets fetch some new
      dispatch('fetchRecipes', version);
    },
    fetchRecipes({ state, commit }, version) {
      // fetch the recipes for the current version
      // tell the app that we're loading recipes
      state.loadingRecipes = true;

      // fetch the recipes for the current version
      fetch(`/json/${version}/recipes.json`)
        .then((response) => response.json())
        .then((json) => {
          commit('updateRecipes', { recipes: json.recipes.recipe, version });
        })
        .then(() => {
          // put the recipes in the local storage
          localStorage.recipes = JSON.stringify(state.recipes);
        })
        .catch((err) => console.error(err))
        .then(() => {
          // tell the app that we're no longer loading recipes
          state.loadingRecipes = false;
        });
    },
    fetchLocalization({ state, commit }) {
      // get the localization csv file for the selected language
      fetch(`/localization/${state.settings.selectedLocalization}.csv`)
        .then((response) => response.text())
        .then((text) => {
          // read and split the csv document into rows
          const csvRows = text.split('\n');
          const csvCols = [[], []];

          // split the rows and populate the cols array with the data
          for (let i = 0; i < csvRows.length; i++) {
            const cols = csvRows[i].split(';');
            csvCols[0].push(cols[0]);
            if (cols[1]) {
              csvCols[1].push(cols[1].trimEnd());
            }
          }

          // set the localizationKeys and localizationValues to the new arrays
          commit('updateLocalization', {
            keys: csvCols[0],
            values: csvCols[1],
          });
        })
        .catch((err) => console.error(err));
    },
    addTodoItem({ commit }, payload) {
      // build the todoItem object
      const todoItem = {
        hover: false,
        completed: false,
        collapseList: false,
        disabled: false,
        ...payload,
      };

      // send the todoItem to the commit method
      commit('pushToTodolist', todoItem);
    },
    setLocalStorageTodoList({ state }) {
      localStorage.setItem('todoList', JSON.stringify(state.todoList));
    },
  },
  getters: {
    versionRecipes: (state) => {
      // get the recipes for the current game version
      return state.recipes[state.settings.selectedGameVersion];
    },
    translate: (state) => (key) => {
      // get the translated string from localization.values, if one exists, otherwise use key
      const localized =
        state.localization.values[state.localization.keys.indexOf(key)];
      return typeof localized === 'undefined' ? key : localized;
    },
    getTodoList: (state) => {
      return state.todoList.filter((todo) => todo.disabled === false);
    },
    getTodoByIndex: (state, getters) => (index) => {
      const activeTodoList = getters.getTodoList;
      return activeTodoList[index];
    },
    getRecipeSearchAmount: (state) => {
      return state.recipeSearchAmount;
    },
    getTodoListEntry: (state, getters) => (path) => {
      let foundItem;

      // go through all indexes in the path
      for (let i = 0; i < path.length; i++) {
        const item = path[i];

        // build the nested reference to the item
        if (i === 0) {
          foundItem = getters.getTodoByIndex(item);
        } else {
          foundItem = foundItem.children[item];
        }
      }
      return foundItem;
    },
    getTodoListTotals: (state) => {
      // // get the list of ingredients recursively from the payload recipe
      function extractIngredients(recipe) {
        let ingredients = [];

        if (recipe.children) {
          for (let i = 0; i < recipe.children.length; i++) {
            const c = recipe.children[i];

            // don't add completed todo items and ingredients
            if (c.completed) {
              continue;
            }

            // compose and push an ingredient object
            ingredients.push({
              name: c.name,
              nameLocalized: c.nameLocalized,
              amount: c.amount,
              completed: c.completed,
            });

            // go to next level of the ingredients
            ingredients = ingredients.concat(extractIngredients(c));
          }
        }

        return ingredients;
      }

      function arrayUnique(array) {
        const a = array.concat();
        for (let i = 0; i < a.length; ++i) {
          for (let j = i + 1; j < a.length; ++j) {
            if (a[i].name === a[j].name) {
              a[i].amount += a[j].amount;

              // if (a[j].completed) {
              //   // make sure that completedAmount is set
              //   a[i].completedAmount = a[i].completedAmount || 0;

              //   // add newly completed amount
              //   a[i].completedAmount += a[j].amount;
              //   console.log('a[i] :>> ', a[i]);
              // }
              a.splice(j--, 1);
            }
          }
        }

        return a;
      }

      function compare(a, b) {
        if (a.nameLocalized < b.nameLocalized) {
          return -1;
        }
        if (a.nameLocalized > b.nameLocalized) {
          return 1;
        }
        return 0;
      }

      const extractedIngredients = [];

      // push all todo items and ingredients to a global list
      for (let i = 0; i < state.todoList.length; i++) {
        if (!state.todoList[i].completed) {
          extractedIngredients.push(
            {
              name: state.todoList[i].name,
              nameLocalized: state.todoList[i].nameLocalized,
              amount: state.todoList[i].amount,
              completed: state.todoList[i].completed,
            },
            ...extractIngredients(state.todoList[i])
          );
        }
      }

      // return an array of unique ingredients, removing all dublicates
      return arrayUnique(extractedIngredients).sort(compare);
    },
    getTodoListActiveCount: (state) => {
      return state.todoList.filter((v) => !v.completed).length;
    },
  },
  modules: {},
});
