var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.objectPath)?_c('v-list',{staticClass:"pb-0 mb-2",class:{
    'pl-3': !_vm.firstList,
    'pt-0': !_vm.firstList,
  },attrs:{"dense":""}},[_vm._l((_vm.item.children),function(ingredient,index){return [_c('v-list-item',{key:'item_' + index + ingredient.name,staticClass:"px-1",attrs:{"dense":""}},[_c('v-expand-x-transition',[_c('div',{directives:[{name:"show",rawName:"v-show",value:(ingredient.completed),expression:"ingredient.completed"}],staticClass:"completedIndicator"})]),_c('v-list-item-content',{staticClass:"py-0"},[_c('v-list-item-title',[_c('v-container',[_c('v-row',[_c('v-col',{staticClass:"py-0 pt-1 px-0",class:{ showPointer: ingredient.children },on:{"click":function($event){ingredient.children
                    ? _vm.showChildren.splice(index, 1, !_vm.showChildren[index])
                    : function () {}}}},[(ingredient.children)?_c('v-icon',{staticClass:"collapsible",class:{ 'rotate-upside-down': _vm.showChildren[index] },attrs:{"small":""}},[(!_vm.showChildren[index])?[_vm._v("mdi-plus")]:[_vm._v("mdi-minus")]],2):_vm._e(),(!ingredient.children)?_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-circle-medium")]):_vm._e(),_c('span',[_vm._v(_vm._s(ingredient.nameLocalized))])],1),_c('v-col',{staticClass:"py-0 pt-1",attrs:{"cols":"auto"}},[_vm._v(_vm._s(ingredient.amount))])],1)],1)],1)],1),_c('v-list-item-action',{staticClass:"my-1"},[_c('v-tooltip',{attrs:{"bottom":"","disabled":_vm.parentCompleted || ingredient.completed},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c('v-checkbox',{attrs:{"input-value":_vm.parentCompleted || ingredient.completed,"disabled":_vm.parentCompleted},on:{"change":function($event){return _vm.toggleIngredientCompleted(ingredient, index)}}})],1)]}}],null,true)},[_c('span',[_vm._v("Mark as completed")])])],1)],1),_c('div',{key:'list_' + index + ingredient.name,staticClass:"ingredientsContainer"},[_c('v-expand-transition',[(ingredient.children && _vm.showChildren[index])?_c('TodoItemIngredient',{attrs:{"objectPath":_vm.objectPath.concat( [index]),"parentCompleted":ingredient.completed || _vm.parentCompleted}}):_vm._e()],1)],1)]})],2):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }