<template>
  <v-bottom-sheet v-model="show" persistent hide-overlay>
    <v-sheet class="py-2" color="accent" elevation="10">
      <v-container v-show="page1">
        <v-row align="center">
          <v-col cols="auto">
            <v-img
              src="/images/Drevil_million_dollars.jpg"
              max-height="63"
              max-width="60"
              contain
              class="borderedImage"
            />
          </v-col>
          <v-col>
            This site uses cookies for analytics to improve the experience of
            the site and track your every thought throughout the day. We might
            even change your thoughts, if you accept!<br />
            May we be allowed to do this?
          </v-col>
          <v-col cols="12" md="auto">
            <v-btn color="secondary" @click="denyCookies" class="mr-2"
              >No thanks</v-btn
            >
            <v-btn color="secondary" @click="acceptCookies">
              Accept cookies
            </v-btn>
          </v-col>
        </v-row>
      </v-container>

      <v-container v-show="page2">
        <v-row align="center">
          <v-col cols="auto">
            <v-img
              src="/images/really.jpg"
              max-height="63"
              max-width="93"
              contain
              class="borderedImage"
            />
          </v-col>
          <v-col>
            But we really want to know everything about you, your family and
            friends, pleeeaassee??!!
          </v-col>
          <v-col cols="12" md="auto">
            <v-btn color="secondary" @click="denyCookies" class="mr-2 ml-auto"
              >I said NO!</v-btn
            >
            <v-btn color="secondary" @click="acceptCookies" class="mr-auto">
              Alright, track me..!
            </v-btn>
          </v-col>
        </v-row>
      </v-container>

      <v-container v-show="thankyou">
        <v-row align="center">
          <v-col cols="auto">
            <v-img
              src="/images/bean_thank_you.jpg"
              max-height="150"
              max-width="137"
              contain
              class="borderedImage"
            />
          </v-col>
          <v-col>
            Oh thank you, thank you, thank you!!!<br />
            You will forever be in our hearts, mind and tracking software..!<br />
            And remember, we wil be watching you...
            <br /><br />
            <span class="selfDestruct" :class="{ counting: countingClass }">
              This message will self-destruct in
              {{ secondsToClose }} seconds
            </span>
          </v-col>
          <v-col cols="12" md="auto">
            <v-btn color="secondary" @click="closeNow()"> Ok, bye now!</v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-sheet>
  </v-bottom-sheet>
</template>

<script>
export default {
  name: 'CookieConsent',
  data() {
    return {
      show: false,
      page1: true,
      page2: false,
      thankyou: false,
      secondsToClose: -1,
      countingClass: false,
    };
  },
  created() {
    // retrieve the localstorage value, if it exists
    const storedValue = localStorage.hideCookieConsent;

    if (typeof storedValue === 'undefined') {
      // it didn't exist, so we initiate the cookie consent box
      this.show = true;
    } else if (storedValue === '1') {
      // it exists and the user have previously accepted tracking, so we allow it
      this.activateTracking();
    }
  },
  methods: {
    acceptCookies() {
      // save that the user accepts cookies
      localStorage.setItem('hideCookieConsent', '1');

      // show the thank you page
      this.page1 = false;
      this.page2 = false;
      this.thankyou = true;

      // start the timer
      this.secondsToClose = 10;

      // allow tracking cookies to be set
      this.activateTracking();
    },
    denyCookies() {
      // save that the user didn't want cookies
      localStorage.setItem('hideCookieConsent', '0');

      if (this.page1) {
        // show page 2
        this.page1 = false;
        this.page2 = true;
      } else {
        // close the cookie consent
        this.closeNow();
      }
    },
    closeNow() {
      // hide the cookie consent overlay
      this.show = false;
    },
    activateTracking() {
      // start the google analytics tracking
      window.dataLayer = window.dataLayer || [];
      function gtag() {
        window.dataLayer.push(arguments);
      }
      gtag('js', new Date());

      gtag('config', 'G-PDY0G0EEL7');
    },
  },
  watch: {
    secondsToClose(newValue) {
      // countdown to closing the cookie consent
      if (newValue > 0) {
        setTimeout(() => {
          this.secondsToClose--;
          this.countingClass = true;
          setTimeout(() => {
            this.countingClass = false;
          }, 200);
        }, 1000);
      } else if (newValue === 0) {
        this.closeNow();
      }
    },
  },
};
</script>

<style scoped>
.borderedImage {
  border: 2px solid darkred;
}
.selfDestruct {
  transition: font-size 200ms;
}
.selfDestruct.counting {
  font-size: 0.9em;
}
</style>
