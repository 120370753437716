<!--
  TODO: FavIcon bør laves
 -->

<template>
  <v-app>
    <v-app-bar dense outlined app clipped-right>
      <v-img
        src="@/assets/logo.png"
        alt=""
        max-height="30"
        max-width="30"
        class="mr-2"
      />
      <v-app-bar-title title="Crafting Recipe Assistant Fanmade Tool">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <span v-on="on" v-bind="attrs">C.R.A.F.T.</span>
          </template>
          <span>Crafting Recipe Assistant Fanmade Tool</span>
        </v-tooltip>
      </v-app-bar-title>
      <v-spacer></v-spacer>

      <!-- TODO: måske skal denne menu lægges over i venstre side som en navigation drawer også? -->
      <v-menu left offset-y nudge-top="8" :close-on-content-click="false">
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on">
            <v-icon>mdi-cog</v-icon>
          </v-btn>
        </template>

        <v-card>
          <v-subheader>Settings</v-subheader>
          <v-list dense width="300">
            <v-list-item>
              <v-list-item-action>
                <v-switch
                  v-model="darkMode"
                  hide-details
                  inset
                  @change="setDarkMode()"
                ></v-switch>
              </v-list-item-action>
              <v-list-item-title>Dark theme</v-list-item-title>
            </v-list-item>

            <v-list-item>
              <!--
                TODO: tilføj et spørgsmåltegn (hint) for hvad dette er til, beskriv at nogle
                af opskrifterne kan være forskellige baseret på versioner
              -->
              <v-list-item-title>Game version</v-list-item-title>
              <v-list-item-action>
                <v-select
                  v-if="generatedFilesInfo && generatedFilesInfo.versions"
                  filled
                  dense
                  hide-details
                  solo
                  :menu-props="{ offsetY: true }"
                  v-model="selectedGameVersion"
                  item-value="folderName"
                  :items="generatedFilesInfo.versions"
                />
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-card>
      </v-menu>

      <v-badge
        :content="getTodoListActiveCount"
        offset-y="20"
        offset-x="4"
        :value="getTodoListActiveCount"
        transition="bounce"
      >
        <v-btn
          text
          class="mr-n2"
          @click.stop="todoListDrawer = !todoListDrawer"
          :color="todoListDrawer ? 'primary' : ''"
        >
          Todo list
          <v-icon right>mdi-format-list-checks</v-icon>
        </v-btn>
      </v-badge>
    </v-app-bar>

    <v-main>
      <v-container>
        <router-view />
      </v-container>
    </v-main>

    <v-navigation-drawer
      v-model="todoListDrawer"
      width="450"
      :floating="this.$vuetify.breakpoint.mdAndDown"
      :temporary="this.$vuetify.breakpoint.mdAndDown"
      app
      right
      v-if="true"
      clipped
    >
      <TodoList :itemClickCallback="todoItemClickCallback" />
    </v-navigation-drawer>

    <CookieConsent />
  </v-app>
</template>

<script>
import TodoList from '@/components/TodoList/index';
import CookieConsent from './components/CookieConsent.vue';
import { mapGetters } from 'vuex';

export default {
  data() {
    return {
      darkMode: false,
      generatedFilesInfo: {},
      todoListDrawer: null,
    };
  },
  components: {
    TodoList,
    CookieConsent,
  },

  created() {
    // if recipes are stored in the browser local storage
    // and the siteVersion is the same as current, use the localStorage version of recipes
    if (
      localStorage.recipes &&
      localStorage.siteVersion &&
      JSON.parse(localStorage.siteVersion) === this.$store.state.siteVersion
    ) {
      // recipes exists in localStorage, so we fetch them from there
      this.$store.state.recipes = JSON.parse(localStorage.recipes);
    } else {
      //  save the current site version in the local storage
      localStorage.setItem(
        'siteVersion',
        JSON.stringify(this.$store.state.siteVersion)
      );
    }

    if (localStorage.todoListDrawer && this.$vuetify.breakpoint.lgAndUp) {
      this.todoListDrawer = JSON.parse(localStorage.todoListDrawer);
    }

    // if router param "recipe" exists, set it as a search term
    if (this.$router.currentRoute.params.recipe) {
      this.$store.state.recipeSearchTerm = this.$router.currentRoute.params.recipe;
    }

    this.getFilesInfo();
    this.$store.dispatch('fetchLocalization');
  },
  mounted() {
    // if the game version have previously been saved in the local storage, use it
    if (typeof localStorage.darkMode !== 'undefined') {
      this.darkMode = localStorage.darkMode === 'true';
    } else {
      // add event listener, so when changing theme in OS will change website theme
      window
        .matchMedia('(prefers-color-scheme: dark)')
        .addEventListener('change', (e) => {
          this.darkMode = e.matches;
          this.$vuetify.theme.dark = this.darkMode;
        });

      if (
        window.matchMedia &&
        window.matchMedia('(prefers-color-scheme: dark)').matches
      ) {
        // else if the OS prefered a dark theme, use the dark theme
        this.darkMode = true;
      }
    }

    this.$vuetify.theme.dark = this.darkMode;

    // if the game version have previously been saved in the local storage, use it
    if (localStorage.selectedGameVersion) {
      this.selectedGameVersion = localStorage.selectedGameVersion;
    }
  },
  computed: {
    selectedGameVersion: {
      get() {
        return this.$store.state.settings.selectedGameVersion;
      },
      set(value) {
        this.$store.dispatch('updateGameVersion', value);
      },
    },
    ...mapGetters(['getTodoListActiveCount']),
  },
  methods: {
    setDarkMode() {
      // save the dark mode in local storage and set it
      localStorage.darkMode = this.$vuetify.theme.dark = this.darkMode;
    },
    toggleCompleted(ingredient) {
      ingredient.completed = !ingredient.completed;
    },
    getFilesInfo() {
      // get and read the generatedFilesInfo json file and send relevant values to vuex
      fetch('/json/generatedFilesInfo.json')
        .then((response) => response.json())
        .then((json) => {
          this.generatedFilesInfo = json;
        })
        .then(() => {
          // select the first item, if no version have previously been selected or if the version doesn't exist in the current list
          if (
            !this.selectedGameVersion ||
            typeof this.generatedFilesInfo.versions.find(
              (v) => v.folderName === this.selectedGameVersion
            ) === 'undefined'
          ) {
            this.selectedGameVersion = this.generatedFilesInfo.versions[0].folderName;
          }
        })
        .catch((err) => console.error(err));
    },
    todoItemClickCallback() {
      if (this.$vuetify.breakpoint.mdAndDown) {
        this.todoListDrawer = false;
      }
    },
  },
  watch: {
    // getTodoListActiveCount(newValue, oldValue) {
    //   // show the todoList if this was the first item added
    //   if (oldValue === 0 && newValue > 0) {
    //     this.todoListDrawer = true;
    //   }
    // },
    todoListDrawer(newValue) {
      // save the todoListDrawer value to localStorage when it updates
      localStorage.todoListDrawer = JSON.stringify(newValue);
    },
  },
};
</script>

<style lang="scss">
.tooltip {
  border-bottom: 1px dotted white;
}
.bounce-enter-active {
  animation: bounce-in 0.5s;
}
.bounce-leave-active {
  animation: bounce-in 0.5s reverse;
}
@keyframes bounce-in {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
}
</style>
