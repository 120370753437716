<template>
  <div class="ma-2 todoItem">
    <v-row dense @contextmenu.prevent="showMenu()" style="position: relative">
      <v-expand-x-transition>
        <div
          v-show="item.completed"
          class="completedIndicator completedIndicator--item"
        ></div>
      </v-expand-x-transition>
      <v-col>
        <!--
          TODO: magnify ikonet skal gentænkes lidt, i hvert fald på mobil.
                click eventen skifter den bagvedliggende side, men den skal også klappe todo-listen sammen.
        -->
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <span
              v-on="on"
              v-bind="attrs"
              class="text-button item-link"
              @click="
                () => {
                  $store.dispatch('updateSearch', {
                    term: item.name,
                    amount: item.amount,
                  });

                  itemClickCallback();
                }
              "
            >
              {{ item.amount }} {{ item.nameLocalized }}
            </span>
          </template>
          <span>View full recipe</span>
        </v-tooltip>
      </v-col>

      <v-col cols="auto" class="align-center d-flex">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <span v-on="on" v-bind="attrs">
              <v-checkbox
                :input-value="item.completed"
                hide-details
                class="pa-0 my-0 mt-1 d-inline-flex"
                @change="updateIngredient({ completed: !item.completed })"
              ></v-checkbox>
            </span>
          </template>

          <span>Mark as completed</span>
        </v-tooltip>

        <div class="d-inline-flex">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                x-small
                @click="updateIngredient({ collapseList: !item.collapseList })"
                v-on="on"
                v-bind="attrs"
              >
                <v-icon v-if="item.collapseList"
                  >mdi-arrow-expand-vertical</v-icon
                >
                <v-icon v-if="!item.collapseList"
                  >mdi-arrow-collapse-vertical</v-icon
                >
              </v-btn>
            </template>

            <span v-if="item.collapseList">expand the list</span>
            <span v-if="!item.collapseList">collapse the list</span>
          </v-tooltip>

          <v-menu bottom left offset-y v-model="menuActive">
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on" x-small>
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item>
                <v-list-item-title @click="removeItem" class="menu-item">
                  Remove
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
      </v-col>
    </v-row>

    <!-- <v-row dense>
      <v-col class="py-0 mb-2">
        <v-btn icon x-small @click="item.collapseList = !item.collapseList">
          <v-icon v-if="item.collapseList">arrow-expand-vertical</v-icon>
          <v-icon else>mdi-arrow-collapse-vertical</v-icon>
        </v-btn>
      </v-col>
      <v-spacer />
      <v-col cols="auto" class="py-0">
        <v-icon color="green" v-show="item.completed">mdi-check</v-icon>
        <span v-show="!item.completed">
          {{ showNumberOfCompleted() }}
        </span>
      </v-col>
    </v-row> -->

    <v-expand-transition>
      <TodoItemIngredient
        v-show="!item.collapseList"
        :firstList="true"
        :objectPath="[itemIndex]"
        :parentCompleted="item.completed"
      />
    </v-expand-transition>

    <v-divider />
  </div>
</template>

<script>
import TodoItemIngredient from './TodoItemIngredient.vue';
export default {
  components: { TodoItemIngredient },
  name: 'TodoItem',
  data() {
    return {
      menuActive: false,
    };
  },
  props: {
    itemName: {
      type: String,
      default: '',
    },
    itemIndex: {
      type: Number,
      default: 0,
    },
    itemClickCallback: {
      type: Function,
      required: true,
    },
  },
  methods: {
    updateIngredient(changes) {
      // update the state
      this.$store.commit('updateTodoListIngredient', {
        path: [this.itemIndex],
        changes, // : { completed: !item.completed },
      });

      // update localstorage
      this.$store.dispatch('setLocalStorageTodoList');

      // // collapse the item list after a small delay if all ingredients are completed
      // if (this.item.children.length === this.numberOfCompleted()) {
      //   setTimeout(() => {
      //     this.item.collapseList = true;
      //     this.$store.dispatch('setLocalStorageTodoList');
      //   }, 300);
      // }
    },
    showNumberOfCompleted() {
      // get the length of a list of ingredients, marked as completed
      const completed = this.numberOfCompleted();
      return completed + '/' + this.item.children.length;
    },
    numberOfCompleted() {
      // get the length of a list of ingredients, marked as completed
      return this.item.children.filter((ingredient) => {
        return ingredient.completed;
      }).length;
    },
    showMenu() {
      console.log('show Menu (right click menu not implemented!)');
      this.menuActive = true;
    },
    removeItem() {
      this.item.disabled = true;
      this.$store.commit('removeTodoListItem', this.itemIndex);
      this.$store.dispatch('setLocalStorageTodoList');
    },
  },
  computed: {
    item() {
      return this.$store.getters.getTodoByIndex(this.itemIndex);
    },
  },
};
</script>

<style scoped>
.item-link,
.menu-item {
  cursor: pointer;
}

.item-link:hover {
  color: #2196f3; /* primary color */
}

.completedIndicator {
  height: 1px;
  background: #000;
  position: absolute;
  top: 50%;
  right: 38px;
  left: 2%;
  z-index: 1;
  pointer-events: none;
}

.completedIndicator--item {
  top: 20px;
}

.theme--dark .completedIndicator {
  background: #fff;
}
</style>
