<template>
  <v-list
    dense
    v-if="objectPath"
    class="pb-0 mb-2"
    :class="{
      'pl-3': !firstList,
      'pt-0': !firstList,
    }"
  >
    <template v-for="(ingredient, index) in item.children">
      <v-list-item class="px-1" dense :key="'item_' + index + ingredient.name">
        <v-expand-x-transition>
          <div v-show="ingredient.completed" class="completedIndicator"></div>
        </v-expand-x-transition>
        <!-- <v-list-item-avatar
          class="my-0 mr-1"
          tile
          min-width="30"
          min-height="30"
          height="30"
          width="30"
        >
          <v-img :src="`/images/resources/${ingredient.name}.png`"></v-img>
        </v-list-item-avatar> -->

        <v-list-item-content class="py-0">
          <v-list-item-title>
            <v-container>
              <v-row>
                <v-col
                  class="py-0 pt-1 px-0"
                  :class="{ showPointer: ingredient.children }"
                  @click="
                    ingredient.children
                      ? showChildren.splice(index, 1, !showChildren[index])
                      : () => {}
                  "
                >
                  <v-icon
                    v-if="ingredient.children"
                    class="collapsible"
                    :class="{ 'rotate-upside-down': showChildren[index] }"
                    small
                  >
                    <template v-if="!showChildren[index]">mdi-plus</template>
                    <template v-else>mdi-minus</template>
                  </v-icon>

                  <v-icon v-if="!ingredient.children" small
                    >mdi-circle-medium</v-icon
                  >

                  <span>{{ ingredient.nameLocalized }}</span>
                </v-col>
                <v-col class="py-0 pt-1" cols="auto">{{
                  ingredient.amount
                }}</v-col>
              </v-row>
            </v-container>
          </v-list-item-title>
        </v-list-item-content>

        <v-list-item-action class="my-1">
          <v-tooltip bottom :disabled="parentCompleted || ingredient.completed">
            <template v-slot:activator="{ on, attrs }">
              <span v-on="on" v-bind="attrs">
                <v-checkbox
                  :input-value="parentCompleted || ingredient.completed"
                  :disabled="parentCompleted"
                  @change="toggleIngredientCompleted(ingredient, index)"
                ></v-checkbox>
              </span>
            </template>

            <span>Mark as completed</span>
          </v-tooltip>
        </v-list-item-action>
      </v-list-item>

      <div
        class="ingredientsContainer"
        :key="'list_' + index + ingredient.name"
      >
        <v-expand-transition>
          <TodoItemIngredient
            v-if="ingredient.children && showChildren[index]"
            :objectPath="[...objectPath, index]"
            :parentCompleted="ingredient.completed || parentCompleted"
          />
        </v-expand-transition></div
    ></template>
  </v-list>
</template>

<script>
export default {
  name: 'TodoItemIngredient',
  props: {
    firstList: {
      type: Boolean,
      default: false,
    },
    objectPath: {},
    parentCompleted: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showChildren: [
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
      ],
    };
  },
  computed: {
    item: {
      get() {
        return this.$store.getters.getTodoListEntry(this.objectPath);
      },
    },
  },
  methods: {
    toggleIngredientCompleted(ingredient, index) {
      // build the index array from previous levels of objectPaths and current objectPath
      const indexes = [...this.objectPath, index];

      // update the state
      this.$store.commit('updateTodoListIngredient', {
        path: indexes,
        changes: { completed: !ingredient.completed },
      });

      this.$store.dispatch('setLocalStorageTodoList');

      // // collapse the item list after a small delay if all ingredients are completed
      // if (this.item.children.length === this.numberOfCompleted()) {
      //   setTimeout(() => {
      //     this.item.collapseList = true;
      //     this.$store.dispatch('setLocalStorageTodoList');
      //   }, 300);
      // }
    },
    // ...mapGetters(['getTodoListEntry']),
  },
};
</script>
