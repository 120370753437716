import Vue from 'vue';
import VueRouter from 'vue-router';
import Store from '@/store';
import Home from '../views/Home.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/recipe/:recipe',
    name: 'Recipe',
    component: Home,
  },
  {
    path: '/about',
    name: 'About',
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/About.vue'),
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

// register back / forward button press and fix a "page does not update" issue
window.popStateDetected = false;
window.addEventListener('popstate', () => {
  window.popStateDetected = true;
});

// hook up to the navigation change
router.afterEach((to) => {
  const IsItABackButton = window.popStateDetected;
  window.popStateDetected = false;
  if (IsItABackButton) {
    const recipeValue = to.params.recipe ? to.params.recipe : '';
    Store.commit('updateSearchTerm', recipeValue);
  }
});

export default router;
