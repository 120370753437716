<template>
  <!--
    TODO: Kig på mobil og sikr at det ser pænt ud. 
    Der kan pt. godt laves lidt om i rækkefølgen med todo list knappen og "crafted in .." teksten
   -->
  <div class="itemRecipe">
    <div v-if="!firstRecipe" class="horizontalLine"></div>

    <div
      v-if="activeRecipes.length > 1"
      class="verticalLine verticalLine--variants"
    ></div>

    <h3>
      <v-container class="pl-2">
        <v-row class="mx-0">
          {{ amount }} x {{ selectedRecipeNameLocalized }}
          <span v-if="activeRecipes.length === 0" class="label-info py-1">{{
            craftedOrFound({})
          }}</span>
          <span
            v-else-if="activeRecipes.length === 1"
            class="label-info py-1"
            >{{ craftedOrFound(activeRecipes[0]) }}</span
          >
          <v-btn
            v-if="activeRecipes.length === 1"
            color="primary"
            x-small
            class="ml-2 mt-1"
            hide-details
            @click="addTodoListItem({ name: selectedRecipe, index: 0 })"
          >
            + Todo list
          </v-btn>
        </v-row>
      </v-container>
    </h3>

    <div
      class="itemRecipeContent"
      v-if="activeRecipes.length > 0"
      :class="{ 'itemRecipeContent--variants': activeRecipes.length > 1 }"
    >
      <div
        v-for="(r, variantIndex) in activeRecipes"
        :key="variantIndex"
        :class="{ recipeVariant: activeRecipes.length > 1 }"
      >
        <div
          class="horizontalLine"
          v-if="r.ingredient && activeRecipes.length > 1"
        ></div>

        <v-container v-if="activeRecipes.length > 1 && r.ingredient">
          <v-row>
            <v-radio-group
              v-model="activeRecipes[0].selectedVariant"
              hide-details
              class="mt-0"
              @change="recipeChangeHandler"
            >
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-radio
                    :value="variantIndex"
                    v-on="on"
                    v-bind="attrs"
                  ></v-radio>
                </template>

                <span>Use this ingredient variant for todo lists</span>
              </v-tooltip>
            </v-radio-group>

            <!-- TODO: Varianter burde huske om de er kollapset eller ej -->
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  @click="() => (r.show = !r.show)"
                  icon
                  v-on="on"
                  v-bind="attrs"
                  class="mr-1"
                >
                  <v-icon v-if="r.show">mdi-eye</v-icon>
                  <v-icon v-else>mdi-eye-off</v-icon>
                </v-btn>
              </template>

              <span>{{ r.show ? 'hide variant' : 'show variant' }}</span>
            </v-tooltip>

            <div class="d-flex align-center">
              variant {{ variantIndex + 1 }}
              <span class="label-info">{{ craftedOrFound(r) }}</span>
            </div>

            <!-- <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <span v-on="on" v-bind="attrs">
                  <v-switch
                    v-model="r.show"
                    hide-details
                    dense
                    class="mt-0"
                    @change="recipeChangeHandler"
                  >
                    <template v-slot:label>
                      variant {{ variantIndex + 1 }}
                      <span class="label-info">{{ craftedOrFound(r) }}</span>
                    </template>
                  </v-switch>
                </span>
              </template>

              <span>toggle variant visibility</span>
            </v-tooltip> -->

            <v-fade-transition>
              <v-btn
                v-if="r.show"
                color="primary"
                x-small
                class="ml-2 mt-2"
                hide-details
                @click="
                  addTodoListItem({ name: selectedRecipe, index: variantIndex })
                "
              >
                + Todo list
              </v-btn>
            </v-fade-transition>
          </v-row>
        </v-container>

        <v-expand-transition>
          <div
            class="recipeIngredient"
            v-if="r.ingredient"
            v-show="
              r.ingredient &&
              (activeRecipes.length === 1 ||
                (activeRecipes.length > 1 && r.show))
            "
          >
            <div
              class="verticalLine"
              v-show="
                r.ingredient &&
                (activeRecipes.length === 1 ||
                  (activeRecipes.length > 1 && r.show))
              "
            ></div>

            <itemRecipe
              v-for="(ingr, ingrIndex) in r.ingredient"
              :key="ingr.name[0] + ingrIndex"
              :selectedRecipe="ingr.name[0]"
              :amount="parseInt(ingr.count[0]) * amount"
              :treeCallback="recipeTreeCallback"
              :parentVariantIndex="variantIndex"
            />
          </div>
        </v-expand-transition>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'ItemRecipe',
  props: {
    firstRecipe: {
      type: Boolean,
      default: false,
    },
    selectedRecipe: String,
    parentVariantIndex: { type: Number, default: 0 },
    amount: Number,
    treeCallback: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      subTree: [],
    };
  },
  mounted() {
    this.buildTreeCallback();
  },
  updated() {
    this.buildTreeCallback();
  },
  methods: {
    craftedIn(recipeObject) {
      // define where the recipe is crafted and which tools are required
      const craftedIn = recipeObject.craft_area
        ? recipeObject.craft_area[0]
        : 'Backpack';
      const craftTool = recipeObject.craft_tool
        ? ', ' + this.translate(recipeObject.craft_tool[0]) + ' required'
        : '';

      return craftedIn + craftTool;
    },
    craftedOrFound(recipeObject) {
      // define if the recipe is crafted or salvaged/found in world
      if (recipeObject.ingredient) {
        return 'Crafted in ' + this.craftedIn(recipeObject);
      } else {
        return 'Salvaged or found in world';
      }
    },
    translate(value) {
      return this.$store.getters.translate(value);
    },
    recipeChangeHandler() {
      localStorage.recipes = JSON.stringify(this.$store.state.recipes);
    },
    addTodoListItem(recipe) {
      // build a recipe object with basic info and the subtree
      const recipeComposition = {
        name: this.selectedRecipe,
        amount: this.amount,
        nameLocalized: this.selectedRecipeNameLocalized,
        children: this.subTree[recipe.index],
        showChildren: false,
      };

      // TODO: show that the item is sent to the todo list somehow, either "toss to basket", "expand todo list" or something else
      // send the recipeComposition to the store
      this.$store.dispatch('addTodoItem', recipeComposition);
    },
    buildTreeCallback() {
      this.treeCallback({
        name: this.selectedRecipe,
        amount: this.amount,
        nameLocalized: this.selectedRecipeNameLocalized,
        completed: false,
        parentVariantIndex: this.parentVariantIndex,
        ...(this.subTree.length > 0
          ? {
              children: this.subTree[
                this.activeRecipes[0]
                  ? this.activeRecipes[0].selectedVariant
                  : 0
              ],
            }
          : {}),
      });
    },
    recipeTreeCallback(branch) {
      // the parent recipe will execute this

      // create subtree entry, based on the branch parentVariantIndex, if it doesn't exist
      if (!this.subTree[branch.parentVariantIndex]) {
        this.subTree[branch.parentVariantIndex] = [];
      }

      // find the index of the branch
      const branchIndex = this.subTree[branch.parentVariantIndex].findIndex(
        (b) => b.name === branch.name
      );

      if (branchIndex > -1) {
        // if branch exists, replace the branch with new data
        this.subTree[branch.parentVariantIndex][branchIndex] = branch;
      } else {
        // if branch don't exist, push this branch to the array
        this.subTree[branch.parentVariantIndex].push(branch);
      }
    },
  },
  computed: {
    activeRecipes() {
      // if user is changing game version, there is a moment, where there will be no recipes
      if (!this.versionRecipes) {
        return false;
      }

      // retrieve the active recipes from the list of all recipes
      // multiple can be returned, due to variants in crafting methods
      // also don't include recipes, if they don't have ingredients, as almost everything can be salvaged or looted
      const filteredRecipes = this.versionRecipes.filter((recipeToFilter) => {
        if (
          recipeToFilter.name &&
          this.selectedRecipe === recipeToFilter.name[0] &&
          recipeToFilter.ingredient
        ) {
          return recipeToFilter;
        }

        // if nothing is returned above, return null
        return null;
      });
      return filteredRecipes;
    },
    selectedRecipeNameLocalized() {
      // select the localized name, if it exists, otherwise fallback to selectedRecipe
      if (this.activeRecipes[0] && this.activeRecipes[0].nameLocalized) {
        return this.activeRecipes[0].nameLocalized;
      } else {
        return this.translate(this.selectedRecipe);
      }
    },
    ...mapGetters(['versionRecipes']),
  },
};
</script>

<style scoped>
p {
  margin: 0 0 5px;
}

.itemRecipe {
  position: relative;
}

.itemRecipe:last-child::before,
.recipeVariant:last-child::before {
  content: '';
  display: block;
  position: absolute;
  left: -10px;
  top: 15px;
  bottom: 0;
  width: 1px;
  overflow: hidden;
  background: magenta;
}
.recipeVariant:last-child::before {
  left: -15px;
}
.itemRecipeContent {
  padding-left: 15px;
}

.itemRecipeContent--variants {
  padding-left: 20px;
}
.recipeIngredient {
  padding-left: 10px;
  position: relative;
}

.recipeVariant {
  position: relative;
  margin-left: 10px;
}

.recipeVariant > .recipeIngredient {
  margin-left: 10px;
}

.horizontalLine {
  position: absolute;
  left: -10px;
  top: 0.9em;
  height: 1px;
  width: 10px;
  overflow: hidden;
}
.verticalLine {
  display: block;
  position: absolute;
  left: 0;
  top: 1.6em;
  bottom: 0;
  width: 1px;
  overflow: hidden;
}
.recipeIngredient > .verticalLine {
  top: 0;
}

.verticalLine--variants {
  left: 15px;
}

.recipeVariant > .horizontalLine {
  left: -15px;
}

.label-info {
  font-weight: normal;
  padding-left: 8px;
  font-size: 14px;
}

.theme--light .label-info {
  color: rgba(157, 157, 157, 1);
}

.theme--dark .label-info {
  color: rgba(85, 85, 85, 0.8);
}

.theme--light .horizontalLine,
.theme--light .verticalLine {
  background: #dadada;
}

.theme--dark .horizontalLine,
.theme--dark .verticalLine {
  background: #656565;
}

.theme--light .itemRecipe:last-child::before,
.theme--light .recipeVariant:last-child::before {
  background: #fff;
}

.theme--dark .itemRecipe:last-child::before,
.theme--dark .recipeVariant:last-child::before {
  background: #1f2128;
}
</style>
