<template>
  <div>
    <v-card class="mb-5">
      <v-container>
        <v-row>
          <v-col md="10" cols="8">
            <!--
              TODO: Kan man lægge en "kan kun findes i verden" sub-tekst på items,
              allerede i søgeboksen, for at begrænse antallet af kliks på disse?
              Måske med en item-slot eller findes der en default "subtext" property?
            -->
            <v-autocomplete
              dense
              :disabled="loading"
              :loading="loading"
              filled
              hide-details
              auto-select-first
              clearable
              :label="`Recipe search${loading ? ' (loading...)' : ''}`"
              v-model="recipeSearchTerm"
              item-text="nameLocalized"
              item-value="name[0]"
              :items="versionRecipes"
              :menu-props="{ offsetY: true }"
              placeholder="Browse or type to search recipes"
            />
          </v-col>
          <v-col md="2" cols="4">
            <v-text-field
              dense
              hide-details
              filled
              label="Amount"
              v-model.number="amount"
            />
          </v-col>
        </v-row>
      </v-container>
    </v-card>

    <ItemRecipe
      v-if="selectedRecipeExist()"
      :key="recipeSearchTerm"
      :selectedRecipe="recipeSearchTerm"
      :amount="amount"
      :firstRecipe="true"
    />

    <v-container v-if="!recipeSearchTerm">
      <p>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <span class="tooltip" v-on="on" v-bind="attrs">C.R.A.F.T.</span>
          </template>
          <span>Crafting Recipe Assistant Fanmade Tool</span>
        </v-tooltip>
        is a <a href="https://7daystodie.com/">7 Days to Die</a> planning tool,
        giving you an idea of how many resources you need to craft a certain
        amount of items.
      </p>

      <p>Use the form above to browse or search for a recipe.</p>

      <p>
        To get to know the tool, start out with a simple recipe, like
        <a @click="recipeSearchTerm = 'resourceGlue'">Glue</a>
        or
        <a @click="recipeSearchTerm = 'medicalFirstAidBandage'">
          First Aid Bandage </a
        >.
      </p>

      <p>
        The goal of this website is to give players an easy tool to have at
        hand, on mobile or on a secondary monitor while playing or when you are
        simply just planning your next big project.
      </p>

      <!-- 
      &lt;&lt;&lt;&lt;&lt; recent used recipes here.. and suggestions?
      &gt;&gt;&gt;&gt;&gt; -->
    </v-container>
  </div>
</template>

<script>
import ItemRecipe from './ItemRecipe';
import { mapGetters } from 'vuex';
export default {
  name: 'RecipeSearch',
  components: {
    ItemRecipe,
  },
  computed: {
    version: {
      get() {
        return this.$store.state.settings.selectedGameVersion;
      },
    },
    loading: {
      get() {
        return this.$store.state.loadingRecipes;
      },
    },
    recipeSearchTerm: {
      get() {
        return this.$store.state.recipeSearchTerm;
      },
      set(newValue) {
        this.$store.commit('updateSearchTerm', newValue);
      },
    },
    amount: {
      get() {
        return this.$store.getters.getRecipeSearchAmount;
      },
      set(newValue) {
        this.$store.commit('setSearchAmount', newValue);
      },
    },
    ...mapGetters(['versionRecipes']),
  },
  methods: {
    selectedRecipeExist() {
      // return true if recipeSearchTerm has a value and if length is not 0
      return this.recipeSearchTerm && this.recipeSearchTerm.length !== 0;
    },
  },
};
</script>
