<template>
  <v-card elevation="2">
    <v-toolbar dense v-if="$vuetify.breakpoint.mdAndDown">
      <v-spacer></v-spacer>
      <v-btn text @click.stop="itemClickCallback" color="primary" class="mr-n3">
        Todo list
        <v-icon right>mdi-format-list-checks</v-icon>
      </v-btn>
    </v-toolbar>

    <v-card-title v-if="1 == 2">Todo list</v-card-title>
    <v-divider></v-divider>
    <v-card-text class="pa-0">
      <v-banner
        v-if="todoList.length === 0"
        :color="$vuetify.theme.dark ? 'secondary' : '#e3f2fd'"
      >
        <p><strong>No items added to todo list yet.</strong></p>
        <p>
          Click the
          <v-btn color="primary" x-small hide-details>+ Todo list</v-btn>
          on recipes to add them here for easy overview of what you need to
          make.
        </p>
      </v-banner>
      <TodoItem
        v-for="(item, index) in todoList"
        :key="index"
        :itemName="item.name"
        :itemIndex="index"
        :itemClickCallback="itemClickCallback"
      />

      <div v-if="todoList.length > 0">
        <p
          class="text-center ma-2 pb-1 showPointer"
          @click="showTotals = !showTotals"
        >
          <v-icon
            class="collapsible collapsible--totals"
            :class="{ 'rotate-upside-down': showTotals }"
            small
          >
            <template v-if="!showTotals">mdi-plus</template>
            <template v-else>mdi-minus</template>
          </v-icon>
          <span>Total incompleted</span>
        </p>

        <v-expand-transition>
          <v-list dense class="todoTotals" v-if="showTotals">
            <v-list-item v-for="(item, index) in todoListTotals" :key="index">
              <v-list-item-title>
                <v-container>
                  <v-row>
                    <v-col class="py-0 pt-1 px-0">
                      <span>{{ item.nameLocalized }}</span>
                    </v-col>
                    <v-col class="py-0 pt-1" cols="auto">{{
                      item.amount
                    }}</v-col>
                  </v-row>
                </v-container>
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-expand-transition>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import TodoItem from '@/components/TodoList/TodoItem';

export default {
  name: 'TodoList',
  components: {
    TodoItem,
  },
  props: {
    itemClickCallback: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      showTotals: false,
    };
  },
  computed: {
    todoList: {
      get() {
        return this.$store.getters.getTodoList;
      },
    },
    todoListTotals: {
      get() {
        return this.$store.getters.getTodoListTotals;
      },
    },
  },
};
</script>

<style lang="scss">
// .todoItem .v-list-item {
//   font-size: 11px;
// }
.showPointer {
  cursor: pointer;
}

.todoItem .v-list-item:hover,
.todoItem .v-list-item:hover + div .v-list,
.todoItem .v-list-item:hover + div .v-list .v-list {
  background: #e5e5e5;
}

.theme--dark .todoItem .v-list-item:hover,
.theme--dark .todoItem .v-list-item:hover + div .v-list,
.theme--dark .todoItem .v-list-item:hover + div .v-list .v-list {
  background: #252525;
}

.todoItem .v-list--dense .v-list-item,
.todoTotals.v-list--dense .v-list-item {
  min-height: 30px;
}

.todoItem .ingredientsContainer {
  position: relative;
}
.todoItem .v-list-item .v-icon {
  margin-top: -2px;
}

.collapsible {
  transition: transform 0.3s;

  &--totals {
    padding-bottom: 3px;
  }
}

.rotate-upside-down {
  transform: rotate(-180deg);

  &.collapsible--totals {
    padding-bottom: 0;
  }
}

// .todoItem .v-list {
//   list-style: none;
//   margin: 0;
//   padding: 0;
// }
// .todoItem .v-list .v-list {
//   margin-left: 10px;
// }
// .todoItem .v-list .v-list-item {
//   margin: 0;
//   padding: 0 7px;
//   line-height: 20px;
//   // color: #369;
//   font-weight: bold;
//   border-left: 1px solid red;
// }
// .todoItem .v-list .v-list-item:last-child {
//   border-left: none;
// }
// .todoItem .v-list .v-list-item:before {
//   position: relative;
//   top: -0.3em;
//   height: 1em;
//   width: 12px;
//   // color: white;
//   border-bottom: 1px solid orange;
//   content: '';
//   display: inline-block;
//   left: -7px;
// }
// .todoItem .v-list .v-list-item:last-child:before {
//   border-left: 1px solid lightgreen;
// }
</style>
